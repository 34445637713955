<template>
  <div class="text-start px-4 py-2">
    <div class="pb-3 m-3 border-bottom">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <h4>
          {{
            $t("borrow.select_number", selectedCount, { count: selectedCount })
          }}
        </h4>
        <div class="d-flex my-2 overflow-auto align-items-center">
          <button
            class="btn-filled secondary-btn me-3 d-flex my-1 flex-shrink-0"
            @click="$router.back()"
          >
            <p>{{ $t("borrow.cancel") }}</p>
          </button>
          <button
            class="btn-filled primary-btn d-flex my-1 flex-shrink-0"
            @click="confirmSelectAsset"
          >
            <p>{{ $t("borrow.confirm_select") }}</p>
          </button>
        </div>
      </div>
      <div class="d-flex flex-wrap justify-content-between gap-4">
        <div class="d-flex flex-wrap gap-3">
          <div class="d-flex flex-column">
            <p style="color: #7c7c7c;">
              {{ $t("borrow.borrow_filter_start") }}
            </p>
            <date-picker
              v-model="filter.start_datetime"
              locale="en"
              mode="dateTime"
              is24hr
              :min-date="new Date()"
              :masks="masks"
              :minute-increment="60"
              is-required
            >
              <template v-slot="{ inputValue, togglePopover }">
                <input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  readonly
                  @click="togglePopover()"
                />
              </template>
            </date-picker>
          </div>
          <div class="d-flex flex-column">
            <p style="color: #7c7c7c;">{{ $t("borrow.borrow_filter_end") }}</p>
            <date-picker
              v-model="filter.end_datetime"
              locale="en"
              mode="dateTime"
              is24hr
              :min-date="filter.start_datetime"
              :masks="masks"
              :minute-increment="60"
              is-required
            >
              <template v-slot="{ inputValue, togglePopover }">
                <input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  readonly
                  @click="togglePopover()"
                />
              </template>
            </date-picker>
          </div>
        </div>
        <div class="d-flex align-items-center gap-2">
          <div class="position-relative" style="height: fit-content">
            <input
              v-model="filter.text"
              v-debounce:700ms="loadAsset"
              type="text"
              :placeholder="$t('borrow.search')"
              class="form-control"
              style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
            />
            <img
              class="position-absolute top-50 translate-middle-y"
              style="left: 5px"
              src="../../assets/search-white.svg"
              width="20px"
            />
          </div>
          <button
            class="filter flex-shrink-0"
            type="button"
            id="button-addon2"
            @click="filterModal = true"
          >
            <img src="@/assets/filter-white.svg" />
          </button>
        </div>
      </div>
    </div>
    <add-borrow-table
      ref="BorrowTableRef"
      :assetData="tableData"
      :otherColumn="otherColumn"
      :page="currentPage"
      :perPage="perPage"
      @selected-count="setSelectedCount"
      @interface="getBorrowTableInterface"
    ></add-borrow-table>
    <div class="d-flex justify-content-center mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
        @input="loadAsset"
      />
    </div>
    <asset-filter
      :showModal="filterModal"
      @confirm-action="handleFilter"
      @cancel-action="handleClear"
      @hide-modal="filterModal = false"
    ></asset-filter>
  </div>
</template>

<script>
import axios from "axios";
import { borrowUrl } from "../../util/backend";
import { authHeader } from "../../store/actions";

import DatePicker from "v-calendar/lib/components/date-picker.umd";
import AddBorrowTable from "../../components/Table/BorrowReturn/AddBorrowTable.vue";
import AssetFilter from "../../components/BorrowReturn/AssetFilter.vue";
import { mapGetters } from "vuex";
import { AssetStatuses } from "@/constants/borrow-return/AssetStatuses";
export default {
  components: {
    AddBorrowTable,
    DatePicker,
    AssetFilter,
  },
  data() {
    return {
      id_company: null,
      masks: {
        inputDateTime24hr: "DD/MM/YYYY HH:mm",
      },
      currentPage: 1,
      perPage: 10,
      filterModal: false,
      filter: {
        start_datetime: null,
        end_datetime: null,
        text: null,
      },
      filterOptions: null,

      BorrowListTableFunction: {
        getSelectedRowData: () => {},
        showLoading: () => {},
        hideLoading: () => {},
      },
      tableData: null,
      otherColumn: [],
      totalRows: 0,
      selectedCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      getBorrowOrderForm: "borrow/getBorrowOrderForm",
    }),
  },
  methods: {
    getBorrowTableInterface(childInterface) {
      this.BorrowListTableFunction = childInterface;
    },
    handleFilter(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.loadAsset();
    },
    handleClear(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.filter.text = "";
      this.loadAsset();
    },
    setSelectedCount(value) {
      this.selectedCount = value;
    },

    getSelectedAsset() {
      return this.$refs.BorrowTableRef.selectedRowData;
    },
    confirmSelectAsset() {
      this.$store.dispatch(
        "borrow/setSelectedAssetBorrow",
        this.getSelectedAsset()
      );
      this.$router.back();
    },
    async loadAsset() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.id_company = id_company;
      this.BorrowListTableFunction.showLoading();
      const response = await axios.get(
        `${borrowUrl()}asset/list/company/id/${id_company}`,
        {
          params: {
            ...this.filter,
            ...this.filterOptions,
            page: this.currentPage,
            limit: this.perPage,
            request_status: AssetStatuses.AVAILABLE,
          },
          ...authHeader(),
        }
      );
      this.tableData = response.data.results;
      this.totalRows = response.data.item_count;
      this.BorrowListTableFunction.hideLoading();
    },
  },
  watch: {
    "filter.start_datetime"() {
      this.loadAsset();
    },
    "filter.end_datetime"() {
      this.loadAsset();
    },
  },
  beforeDestroy() {
    this.$store.dispatch("borrow/clearBorrowState");
  },
  created() {
    const { data: storeBorrowOrderForm } = this.getBorrowOrderForm;
    if (storeBorrowOrderForm) {
      this.filter.start_datetime = storeBorrowOrderForm.borrowStartDateTime;
      this.filter.end_datetime = storeBorrowOrderForm.borrowEndDateTime;
    }
    this.loadAsset();
  },
};
</script>

<style lang="scss" scoped>
.btn-filled {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #fff;
    background-color: #007afe;
    border-color: #007afe;
  }
  &.secondary-btn {
    color: #fff;
    background-color: #818181;
    border-color: #818181;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
    width: 40px;
  }
}
</style>
